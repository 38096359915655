<template>
    <div class="schedaAttivita" style="height:100%;">
        <v-layout justify-center align-center style="height:100%;">
            <v-flex xs11 md8 lg5>
                <v-toolbar color="secondary">
                    <v-toolbar-title class="white--text">
                        Scheda Attività - <b>#{{ numero }}</b>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <template v-if="isMaster">
                        <v-btn small icon @click="remove_attivita()" color="white">
                            <v-icon small>fas fa-trash</v-icon>
                        </v-btn>
                        <v-btn small icon @click="isEditing = !isEditing" color="white" class="ml-3">
                            <v-icon small>fas fa-edit</v-icon>
                        </v-btn>
                    </template>
                    <v-btn v-if="token" small icon @click="$router.back()" color="white" class="ml-3 mr-1">
                        <v-icon small>fas fa-times</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card outlined>
                    <v-form v-model="formValid" ref="form_data" lazy-formValidation>
                        <v-container>
                            <v-layout column>
                                <v-select :items="['Baggi','LaCelle']"
                                          :readonly="!isEditing"
                                          v-model="appartenenza"
                                          v-if="ruolo === 'Master' || ruolo === 'Tecnico'"
                                          required
                                          filled
                                          hide-details="auto"
                                          :rules="requiredRules"
                                          label="Appartenenza"
                                          class="mb-2" />
                                <v-select v-show="isMaster"
                                          :readonly="!isEditing"
                                          :items="lista_clienti"
                                          v-model="cliente"
                                          required
                                          outlined
                                          :rules="requiredRules"
                                          label="Cliente"
                                          class="mb-0 mt-3"
                                          no-data-text="Nessun Cliente"
                                          item-text="nominativo1"
                                          item-value="_id" />
                                <v-select :readonly="!isEditing"
                                          :items="lista_stabilimenti"
                                          v-model="stabilimento"
                                          required
                                          outlined
                                          :rules="requiredRules"
                                          label="Stabilimento"
                                          :class="isMaster ? 'mb-0' : 'mt-7 mb-0'"
                                          no-data-text="Nessuno Stabilimento"
                                          item-text="indirizzo"
                                          item-value="_id" />
                                <v-select :readonly="!isEditing"
                                          :items="lista_tipi_attivita"
                                          v-model="tipo_attivita"
                                          hide-details="auto"
                                          required
                                          outlined
                                          :rules="requiredRules"
                                          label="Tipo Attività"
                                          class="mb-7" />
                                <v-select :readonly="!isEditing"
                                          :items="stati_attivita"
                                          v-model="stato"
                                          outlined
                                          required
                                          :rules="requiredRules"
                                          label="Stato"
                                          class="mb-7"
                                          hide-details="auto"
                                >
                                    <template v-slot:selection="{item}">
                                        <template v-if="isEditing">
                                            <b :style="'color:'+switchColorStato(item)">{{ item }}</b>
                                        </template>
                                        <template v-else>
                                            <b :style="'color:'+switchColorStato(item)+'80'">{{ item }}</b>
                                        </template>
                                    </template>
                                </v-select>
                                <v-menu ref="menuDataInizio"
                                        v-model="menuDataInizio"
                                        :close-on-content-click="false"
                                        :return-value.sync="data_inizio"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="data_inizio"
                                            label="Data Inizio"
                                            prepend-inner-icon="mdi-calendar"
                                            readonly
                                            class="pt-0 mt-0"
                                            v-bind="attrs"
                                            v-on="isEditing ? on : undefined"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="data_inizio"
                                                   no-title
                                                   scrollable
                                                   locale="it"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menuDataInizio = false">
                                            Chiudi
                                        </v-btn>
                                        <v-btn text color="primary" @click="$refs.menuDataInizio.save(data_inizio)">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                                <v-menu ref="menuDataScadenza"
                                        v-model="menuDataScadenza"
                                        :close-on-content-click="false"
                                        :return-value.sync="data_scadenza"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="data_scadenza"
                                                      label="Data Scadenza"
                                                      prepend-inner-icon="mdi-calendar"
                                                      readonly
                                                      v-bind="attrs"
                                                      v-on="isEditing ? on : undefined"/>
                                    </template>
                                    <v-date-picker v-model="data_scadenza" no-title scrollable locale="it">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menuDataScadenza = false">
                                            Chiudi
                                        </v-btn>
                                        <v-btn text color="primary" @click="$refs.menuDataScadenza.save(data_scadenza)">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                                <!--v-text-field
                                    autocomplete="off"
                                    :readonly="!isEditing"
                                    label="Titolo"
                                    class="pt-0"
                                    v-model="titolo"
                                    required
                                    :rules="testoRules"
                                ></v-text-field-->
                                <v-textarea autocomplete="off"
                                            :readonly="!isEditing"
                                            class="mt-3"
                                            label="Note"
                                            v-model="note"
                                            outlined
                                            auto-grow
                                            rows="1" />
                                <v-textarea autocomplete="off"
                                            :readonly="!isEditing"
                                            class="mt-3"
                                            label="Note aggiuntive"
                                            v-model="note_aggiuntive"
                                            outlined
                                            auto-grow
                                            rows="1" />
                                <v-select :readonly="!isEditing"
                                          :items="lista_tecnici"
                                          v-model="assegnazione"
                                          required
                                          outlined
                                          label="Assegnazione"
                                          multiple
                                          class="mb-3"
                                          hide-details="auto"
                                          no-data-text="Nessun Tecnico"
                                          item-text="nominativo1"
                                          item-value="_id" />
                                <v-container fluid v-if="isMaster" class="py-0">
                                    <v-row>
                                        <v-col cols="12" sm="4" class="py-0">
                                            <v-checkbox label="Informa Utente" dense disabled v-model="informaUtente"></v-checkbox>
                                        </v-col>
                                        <v-col cols="12" sm="4" class="py-0">
                                            <v-checkbox label="Visibile a Utente" dense :readonly="!isEditing" v-model="visibilitaUtente"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <div style="display: inline-flex;" class="mt-3 mb-1">
                                    <h3 class="ml-1">Elenco rapporti</h3>
                                    <v-spacer></v-spacer>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small icon outlined @click="downloadListaEvacuatori()" color="greyColor" class="ml-3 mr-1 pa-0" v-bind="attrs" v-on="on">
                                                <v-icon x-small color="primary">fas fa-download</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Scarica Elenco Rapporti</span>
                                    </v-tooltip>
                                </div>
                                <v-col class="pt-0 pb-0 pl-0 pr-0">
                                    <v-select v-if="full_stabilimento && full_stabilimento.reparti && full_stabilimento.reparti.length"
                                              :items="[{_id: '', nome:'Tutti'}].concat(full_stabilimento.reparti)"
                                              v-model="filtroReparto"
                                              item-text="nome"
                                              item-value="_id"
                                              label="Seleziona un reparto" />
                                </v-col>
                                <hr color="LightGrey">
                                <ListaRapportini v-if="!needReload"
                                                 :id_attivita="id"
                                                 :id_reparto="filtroReparto"
                                                 :isMaster="isMaster"
                                                 :libero="rapportiLiberi" />
                                <v-col class="mt-0 pt-0 px-1" cols="12" sm="4" offset-sm="4" v-if="isMaster">
                                    <v-btn color="primary" width="100%" @click="showModalNewRapporto = true">Crea rapporto</v-btn>
                                </v-col>
                            </v-layout>
                        </v-container>
                    </v-form>
                    <v-card-actions>
                        <v-col class="mt-0 pt-0 px-1" cols="4" sm="3" :offset-sm="isMaster ? (idDDT ? 0 : 2) : 0" v-if="isMaster && token">
                            <v-btn color="primary" width="100%" @click="modalDDT = true" v-text="idDDT ? 'Vedi DDT' : 'Crea DDT'"></v-btn>
                        </v-col>
                        <v-col class="mt-0 pt-0 px-1" cols="4" :sm="isMaster && !idDDT ? 3 : 4" :offset-sm="isMaster ? (idDDT ? 1 : 2) : (idDDT ? 1 : 4)" v-if="token">
                            <v-btn color="primary" width="100%" @click="submit" :disabled="isEditing && !formValid && loading" v-text="isEditing ? 'Salva' : 'Indietro'"></v-btn>
                        </v-col>
                        <v-col class="mt-0 pt-0 px-1" cols="4" :sm="isMaster ? 3 : 4" :offset-sm="isMaster ? 1 : 2" v-if="idDDT">
                            <v-btn color="primary" width="100%" :to="'/anteprimaDDT?_id=' + idDDT">Anteprima DDT</v-btn>
                        </v-col>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
        <v-dialog v-model="modalDDT"
                  width="auto"
                  max-width="700"
                  class="overflowXhidden"
        >
            <NewDDT v-if="modalDDT"
                    :idAttivita="id"
                    :id_cliente="cliente"
                    :id_stabilimento="stabilimento"
                    :id="idDDT"
                    @DDTdone="modalDDT = false;reloadDDTdata();"
                    class="overflowXhidden"/>
        </v-dialog>
        <v-dialog v-model="showModalNewRapporto"
                  width="auto"
                  max-width="700"
        >
            <NewRapporto v-if="showModalNewRapporto"
                         :id_attivita="id"
                         :id_cliente="cliente"
                         :id_stabilimento="stabilimento"
                         :libero="rapportiLiberi"
                         @rapportinoDone="showModalNewRapporto = false;reloadRapportiniData()"
                         class="overflowXhidden"/>
        </v-dialog>
    </div>
</template>

<script>
import apiUtenti from '@/js/pages/utenti';
import apiStabilimenti from '@/js/pages/stabilimenti';
import apiAttivita from '@/js/pages/attivita';
import { testoRules, requiredRules } from '@/js/validationRules';
const NewDDT = () => import('@/components/NewDDT');
const NewRapporto = () => import('@/components/NewRapporto');
const ListaRapportini = () => import('@/components/Lista_rapportini');

export default {
    name: 'schedaAttivita',
    components: {
        NewDDT,
        ListaRapportini,
        NewRapporto
    },
    data() {
        return {
            token: this.$cookies.get('token'),
            ruolo: this.$store.state.ruolo,
            id: this.$route.query._id,
            isEditing: false,
            titolo: '',
            numero: '',
            note: '',
			note_aggiuntive: '',
            informaUtente: false,
            visibilitaUtente: false,
            cliente: undefined,
            stabilimento: undefined,
            lista_clienti: [],
            lista_stabilimenti: [],
            formValid: false,
            testoRules: testoRules,
            requiredRules: requiredRules,
            loading: false,
            lista_permessi: ['Master', 'Tecnico', 'Partner', 'Cliente'],
            lista_tipi_attivita: ['Manutenzione', 'Posa', 'Controllo', 'Altro'],
            tipo_attivita: '',
            menuDataInizio: false,
            data_inizio: '',
            menuDataScadenza: false,
            data_scadenza: '',
            modalDDT: false,
            idDDT: '',
            lista_tecnici: [],
            assegnazione: undefined,
            isMaster: false,
            isTecnico: false,
            stato: '',
            stati_attivita: ['Nuova', 'In Elaborazione', 'Sospesa', 'Conclusa'],
            showModalNewRapporto: false,
            needReload: false,
            appartenenza: '',
            filtroReparto: undefined,
            full_stabilimento: undefined
        }
    },
    watch: {
        async cliente() {
            if(this.token) {
                this.lista_stabilimenti = [];
                let lista_stabilimenti = await apiStabilimenti.getAll(this.token, this.cliente);
                this.lista_stabilimenti = lista_stabilimenti || [];
                if(
                    lista_stabilimenti &&
                    lista_stabilimenti.length === 1 &&
                    !this.stabilimento
                ) this.stabilimento = lista_stabilimenti[0]['_id'];
            }
        },
        ruolo() {
            if(this.ruolo === 'Partner') {
                this.appartenenza = 'Baggi';
                this.lista_permessi = ['Cliente'];
            }
        }
    },
    computed: {
        rapportiLiberi() {
            if(this.tipo_attivita === 'Manutenzione') return false;
            else return true;
        }
    },
    async created() {
        await this.initAttivita();
    },
    methods: {
        async initAttivita() {
            if (this.$store.state.ruolo) {
                if (this.$store.state.ruolo === 'Cliente') this.isMaster = false;
                else this.isMaster = true;
                if (this.$store.state.ruolo === 'Tecnico') this.isTecnico = false;
                else this.isTecnico = true;
            } else {
                this.isMaster = false;
                this.isTecnico = false;
            }
            if (this.token) {
                if (this.ruolo === 'Partner') {
                    this.appartenenza = 'Baggi';
                    this.lista_permessi = ['Cliente'];
                    this.lista_clienti = await apiUtenti.getAllClientPartner(this.token, 'Baggi');
                } else this.lista_clienti = await apiUtenti.getAll(this.token, 'Cliente');

                let arr_tecnici = await apiUtenti.getAll(this.token, 'Tecnico');
                let arr_master = await apiUtenti.getAll(this.token, 'Master');
                let arr = [];
                if (
                    arr_tecnici &&
                    arr_tecnici.length
                ) Array.prototype.push.apply(arr, arr_tecnici);
                if (
                    arr_master &&
                    arr_master.length
                ) Array.prototype.push.apply(arr, arr_master);
                this.lista_tecnici = arr;
            }

            if (!this.id) this.id = this.$route.query._id;
            let attivita = await apiAttivita.getOne(this.id);
            this.numero = attivita.numero || '';
            this.titolo = attivita.titolo || '';
            this.note = attivita.note || '';
            this.note_aggiuntive = attivita.note_aggiuntive || '';
            this.stato = attivita.stato || '';
            this.cliente = attivita.id_cliente;
            if (attivita.id_cliente) {
                let utente = await apiUtenti.getUtenteFromId(attivita.id_cliente);
                if (utente) this.lista_clienti = [utente];
            }
            this.stabilimento = attivita.id_stabilimento;
            if (
                attivita.full_stabilimento &&
                attivita.full_stabilimento[0]
            ) this.full_stabilimento = attivita.full_stabilimento[0];

            if (
                attivita.assegnazione &&
                attivita.assegnazione.length
            ) {
                this.assegnazione = attivita.assegnazione;
                for (const assegnazione of attivita.assegnazione) {
                    let dataUser = await apiUtenti.getUtenteFromId(assegnazione);
                    if (dataUser) this.lista_tecnici.push(dataUser);
                }
            }
            if (attivita.informa_utente) this.informaUtente = true;
            if (attivita.visibilita_utente) this.visibilitaUtente = true;
            this.tipo_attivita = attivita.tipo_attivita;
            this.idDDT = attivita.ddt && attivita.ddt[0] ? attivita.ddt[0]._id : undefined;
            this.data_inizio = attivita.data_inizio ? new Date(attivita.data_inizio).toISOString().substr(0, 10) : '';
            this.data_scadenza = attivita.data_scadenza ? new Date(attivita.data_scadenza).toISOString().substr(0, 10) : '';
            this.appartenenza = attivita.appartenenza || 'LaCelle';
        },
        async reloadDDTdata() {
            let attivita = await apiAttivita.getOne(this.id);
            this.idDDT = attivita.ddt && attivita.ddt[0] ? attivita.ddt[0]._id : undefined;
        },
        async reloadRapportiniData() {
            this.needReload = true;
            let those = this;
            setTimeout(() => {
                those.needReload = false;
            }, 10);
        },
        async submit(){
            if(this.isEditing){
                this.loading = true;
                if(
                    this.$refs.form_data.validate() &&
                    this.formValid
                ){
                    let data = {
                        _id: this.id,
                        titolo: this.titolo,
                        tipo_attivita: this.tipo_attivita,
                        visibilita_utente: this.visibilitaUtente,
                        note: this.note,
						note_aggiuntive: this.note_aggiuntive,
                        stato: this.stato,
                        id_cliente: this.cliente,
                        id_stabilimento: this.stabilimento,
                        data_inizio: this.data_inizio,
                        data_scadenza: this.data_scadenza,
                        assegnazione: this.assegnazione,
                        appartenenza: this.appartenenza,
                    };
                    await apiAttivita.updateOne(this.token, data);
                    this.$router.push('/listAttivita');
                }
                this.loading = false;
            } else this.$router.back();
        },
        async remove_attivita(){
            if(confirm("Eliminare l'attivita?")){
                let data = { _id: this.id };
                await apiAttivita.deleteOne(this.token, data);
                this.$router.push('/listAttivita');
            }
        },
        switchColorStato(stato){
            switch (stato) {
                case 'Nuova':
                    return '#2EB2FF';
                case 'In Elaborazione':
                    return '#65BE0A';
                case 'Sospesa':
                    return '#FF333A';
                case 'Conclusa':
                    return '#FFA500';
                default:
                    return '#2EB2FF';
            }
        },
        async downloadListaEvacuatori() {
            let nomeFile = await apiAttivita.downloadElenco(this.token, { id_attivita: this.id });
            if(nomeFile) {
                window.open(process.env.VUE_APP_ENDPOINT_API + '/' + nomeFile, '_BLANK');
            } else {
                alert("Ci sono stati degli errori durante l'esportazione. Riprovare" );
                location.reload();
            }
        }
    }
}
</script>
